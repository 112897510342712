/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { withErrorHandled } from '@/components/dls/ErrorHandler'
import type { megaNavItem, MegaNavProps } from './types'

const MegaNavItem = dynamic(import('./MobileMegaNavItems').then((mod) => mod.MegaNavItem))

const Link = dynamic(import('@/components/dls/Link'))

const AlphanumericInput = dynamic(import('@csc/dls/AlphanumericInput'))
const Modal = dynamic(import('@csc/dls/Modal'))
const Text = dynamic(import('@csc/dls/Text'))
const Col = dynamic(import('@csc/dls/Col'))
const Row = dynamic(import('@csc/dls/Row'))

const IconRenderer = dynamic(import('@csc/dls/IconRenderer'))

const SearchIcon = dynamic(import('@csc/dls/Icons/SearchIcon'))
const CloseIcon = dynamic(import('@csc/dls/Icons/CloseIcon'))
const MenuIcon = dynamic(import('@csc/dls/Icons/Menu'))
const ArrowLeft = dynamic(import('@csc/dls/Icons/ArrowLeft'))
const ArrowRight = dynamic(import('@csc/dls/Icons/ArrowRight'))
const UserIcon = dynamic(import('@csc/dls/Icons/User'))

const MobileMegaNavExpandedMenu: React.FC<MegaNavProps> = ({
  megaNavItems: items = [],
  searchBarProps = {
    value: '',
    name: 'MegaNav_searchBar',
    height: 'h-10',
    placeholder: 'Search',
    label: 'Search',
    role: 'search',
    'aria-label': 'Search bar',
  },
  searchBar = (
    <div className="relative">
      <AlphanumericInput {...searchBarProps} />
      <IconRenderer IconComponent={SearchIcon} size="sm" className="absolute right-0 transform top-1/2 -translate-y-1/2 mr-2" />
    </div>
  ),
  headerLinks = [],
  closeOnNavigation = true,
  mobileUserIconText = 'Log In',
  mobileUserRedirectUrl = '/login',
  mobileOrderTrackRedirectUrl = '/order-status',
  mobileOrderTrackRedirectLabel = 'Order Tracking',
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState<megaNavItem | null>(null)
  const locationHref = (typeof window !== 'undefined' && window.location.pathname) || ''
  useEffect(() => {
    if (closeOnNavigation) {
      // Custom links might not reload the page therefore we close on navigation
      setIsExpanded(false)
    }
  }, [locationHref, closeOnNavigation])

  const onClose = () => {
    setIsExpanded(false)
    setSelectedMenu(null)
  }
  return (
    <>
      <button
        id="MegaNav_Mobile_toggle"
        type="button"
        data-testid="MegaNav_Mobile_toggle"
        onClick={() => setIsExpanded((i) => !i)}
        aria-label={isExpanded ? 'Collapse navigation' : 'Expand navigation'}
        className="w-full flex items-start justify-start align-middle"
      >
        <IconRenderer
          IconComponent={isExpanded ? CloseIcon : MenuIcon}
        />
      </button>
      <Modal
        className="p-0 min-w-full mt-0"
        closeable
        type="full"
        open={isExpanded}
      >
        {isExpanded && (
        <Row cols={1}>
          <Col>
            <div className="flex flex-row w-full align-middle items-center">

              <Text size="xl" className="flex-auto w-full">
                {!selectedMenu ? 'Menu' : (
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedMenu(null)
                      window.scrollTo(0, 0)
                    }}
                  >
                    <IconRenderer
                      IconComponent={ArrowLeft}
                      className="inline-block mr-1"
                    />
                    {selectedMenu.title}
                  </button>
                )}
              </Text>
              <button
                onClick={onClose}
                type="button"
                className="p-1 focus:text-secondary border border-white focus:border-secondary focus:megaNav-shadow focus:outline-none"
              >
                <IconRenderer IconComponent={CloseIcon} />
              </button>
            </div>
          </Col>
          <Col>
            {searchBar}
          </Col>
          <Col>
            <Row cols={1} gap="xs">
              {!selectedMenu ? items.map((item) => (
                <Link
                  underline={false}
                  onClick={(e) => {
                    if (item.columns.length) {
                      e?.preventDefault?.()
                      e?.stopPropagation?.()
                      if (typeof window !== 'undefined') {
                        window.scrollTo(0, 0)
                      }
                      setSelectedMenu(item)
                    }
                  }}
                  href={item.url}
                  id={item.id}
                  key={`MainMenu_Item_${String(item.id || item.url)}`}
                  className="no-underline"
                >
                  <Row className="py-2 border-b border-light-gray-separator w-full">
                    <Col span={8}>
                      <Text className="flex-auto">
                        {item.title}
                      </Text>
                    </Col>
                    {item.columns.length && (
                    <Col span={4} className="text-right">
                      <button
                        type="button"
                        className="mx-5 float-right"
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          window.scrollTo(0, 0)
                          setSelectedMenu(item)
                        }}
                      >
                        <IconRenderer IconComponent={ArrowRight} size="xs" />
                      </button>
                    </Col>
                    )}
                  </Row>
                </Link>
              )) : (
                <MegaNavItem
                  {...selectedMenu}
                />
              )}
            </Row>
          </Col>
          {!selectedMenu && (
          <>
            <Col className="pb-2 border-b border-light-gray-separator">
              <Link href={mobileUserRedirectUrl} className="no-underline">
                <div className="flex flex-row items-center align-middle">
                  <IconRenderer IconComponent={UserIcon} />
                  <Text className="flex-auto ml-2">
                    {mobileUserIconText}
                  </Text>
                </div>
              </Link>
            </Col>
            <Col className="pb-2 border-b border-light-gray-separator">
              <Link href={mobileOrderTrackRedirectUrl} className="no-underline">
                <Text>
                  {mobileOrderTrackRedirectLabel}
                </Text>
              </Link>
            </Col>
            <Col>
              <Row cols={2} gap="sm">
                {headerLinks?.map((link, index) => (
                  <Col
                    key={`headerLinks-${link?.mobileBackgroundImage?.file?.url}`}
                    span={index % 3 === 0 ? 2 : 1}
                    className="relative h-32 w-full"
                    style={{
                      backgroundImage: `url(${link?.mobileBackgroundImage?.file?.url || ''}), linear-gradient(90deg, var(--imgCarousel-bg) 100%, var(--imgCarousel-bg) 50%)`,
                      backgroundBlendMode: 'multiply',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                  >
                    <div className="w-full h-full bg-black opacity-50" />
                    <Text size="lg" bold className="text-white text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <Link underline={false} className="h-full w-full" href={link?.url || ''}>
                        {link.title}
                      </Link>
                    </Text>
                  </Col>
                ))}
              </Row>
            </Col>
          </>
          )}
        </Row>
        )}
      </Modal>
    </>
  )
}

export default withErrorHandled(MobileMegaNavExpandedMenu)
